import React, { useEffect, useRef, useState } from 'react';
import { bookCover, bookPages, readPageType } from 'types/data.type';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import { Button } from './button/Button';
import { AudioPlayer, Loading } from './Index';
import BooksService from '@core/services/books.service';
import { ProgressBar } from 'react-bootstrap';
import { TbPlayerTrackNext } from 'react-icons/tb';
import { FaHeadphones } from 'react-icons/fa6';
import { IoCheckmark } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import book_icon from 'assets/imgs/book_icon.png';
import { checkComingSoon, countRemainingDays } from '@core/hooks/common';
import { AccoutType } from 'types/Auth.type';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
interface DetailBookSliderProps {
  title?: string;
  items: bookCover[] | bookPages[] | any[];
  bookType: string;
  studentID: string;
  currentPageRead?: number;
  isExpiredAssign?: boolean;
  bookData?: any;
}

enum BookType {
  LISTEN = 'listen',
  READ = 'read'
}

enum NextAssignmentStatus {
  NEXT_ASSIGNMENT,
  LISTENING,
  READING
}

export const DetailBookSlider: React.FC<DetailBookSliderProps> = ({
  title,
  items,
  bookType,
  studentID,
  currentPageRead = 0,
  isExpiredAssign,
  bookData
}) => {
  const swiperRef = useRef<any>(null);
  const [isChangePage, setIsChangePage] = useState(false);
  const [listBookPagesAlreadyRead, setListBookPagesAlreadyRead] =
    useState<any>();
  const [slideSelectedIndex, setSlideSelectedIndex] = useState<any>();
  const [activePage, setActivePage] = useState<number>(currentPageRead + 1);
  const _paramsURL = useParams();
  const [paramsURL, setParamsURL] = useState({ ..._paramsURL });
  const id = paramsURL?.id?.split('-')[1] || '';
  const [loading, setLoading] = useState(true);
  const [isNextAssignmentStatus, setIsNextAssignmentStatus] =
    useState<number>();
  const [idNextAssignment, setIdNextAssignment] = useState<number>();
  const accountType = localStorage.getItem('user_type');
  const [reGetBookPagesAlreadyRead, setReGetBookPagesAlreadyRead] =
    useState(true);

  const handleReadPage = async (params: readPageType) => {
    const res = await BooksService.postReadPage(params);
    if (res) {
      setReGetBookPagesAlreadyRead(!reGetBookPagesAlreadyRead);
    } else {
      console.log('error');
    }
  };

  const checkPageAlreadyRead = (id: number) => {
    return bookType === BookType.READ
      ? listBookPagesAlreadyRead?.covers?.includes(id)
      : listBookPagesAlreadyRead?.pages?.includes(id);
  };

  const checkNextAssignment = () => {
    if (bookType === BookType.LISTEN) {
      if (bookData?.current_cover_count >= bookData?.total_cover_count) {
        setIsNextAssignmentStatus(NextAssignmentStatus.NEXT_ASSIGNMENT);
      } else {
        setIsNextAssignmentStatus(NextAssignmentStatus.READING);
      }
    }
    if (bookType === BookType.READ) {
      if (bookData?.current_page_count >= bookData?.total_page_count) {
        setIsNextAssignmentStatus(NextAssignmentStatus.NEXT_ASSIGNMENT);
      } else {
        setIsNextAssignmentStatus(NextAssignmentStatus.LISTENING);
      }
    }
  };

  const checkNextAssignmentStatus = (
    isListen: boolean,
    isRead: boolean,
    isFinishedListen: boolean,
    isFinishedRead: boolean
  ) => {
    if (isListen && isRead) {
      return !isFinishedListen || !isFinishedRead;
    }

    if (isListen) {
      return !isFinishedListen;
    }

    if (isRead) {
      return !isFinishedRead;
    }
  };

  const fetchDataBookAssign = async () => {
    let params = {
      page_index: 1,
      page_size: 1000
    };

    const resBooks = await BooksService.getUserBooks(params);
    if (resBooks) {
      const data = resBooks?.data?.items;
      const book = data.find(
        (item: any) =>
          item.id !== parseInt(id) &&
          checkNextAssignmentStatus(
            item?.is_listen,
            item?.is_read,
            item?.is_finished_listen,
            item?.is_finished_read
          ) &&
          countRemainingDays(item?.to_date) > 0
      );
      if (book) {
        setIdNextAssignment(book.id);
      }
    }
  };

  useEffect(() => {
    if (
      items.length > 0 &&
      currentPageRead === 0 &&
      !checkComingSoon(bookData?.from_date)
    ) {
      const params = {
        book_id: parseInt(
          items[currentPageRead > 0 ? currentPageRead - 1 : currentPageRead]
            ?.book_id
        ),
        studen_id: parseInt(studentID),
        book_page: parseInt(
          items[currentPageRead > 0 ? currentPageRead - 1 : currentPageRead]?.id
        ),
        is_listen: bookType === BookType.LISTEN ? true : false,
        is_read: bookType === BookType.READ ? true : false
      };
      if (!isExpiredAssign) {
        handleReadPage(params);
      }
    }
    setLoading(false);
  }, [currentPageRead, items]);

  useEffect(() => {
    checkNextAssignment();
    fetchDataBookAssign();
  }, []);

  const handleSlideChange = (swiper: any) => {
    setLoading(true);
    setIsChangePage(true);
    setSlideSelectedIndex(swiper.activeIndex);
    const activeIndex = swiper.activeIndex;
    const activeSlide = items[activeIndex];
    setActivePage(activeIndex + 1);

    const params = {
      book_id: parseInt(activeSlide?.book_id),
      studen_id: parseInt(studentID),
      book_page: parseInt(activeSlide?.id),
      is_listen: bookType === BookType.LISTEN ? true : false,
      is_read: bookType === BookType.READ ? true : false
    };
    if (!isExpiredAssign && !checkComingSoon(bookData?.from_date)) {
      handleReadPage(params);
    }
    setTimeout(() => {
      setLoading(false);
    }, 10);
  };

  useEffect(() => {
    const getBookPagesAlreadyRead = async () => {
      const res = await BooksService.getPagesAlreadyRead(id);
      if (res?.status === 1) {
        setListBookPagesAlreadyRead(res?.data);
      }
    };

    getBookPagesAlreadyRead();
  }, [reGetBookPagesAlreadyRead, id]);

  return (
    <>
      <div className="flex flex-col gap-4 items-center my-4">
        <p className="text-[28px] font-medium text-center">{title}</p>
        <div
          // className="flex gap-2 w-full md:w-[500px] justify-center items-center"
          className="flex flex-wrap gap-2 w-full md:w-[500px] items-center justify-center"
        >
          {items?.map((item: any, index: number) => (
            <div
              className={`w-6 h-6 rounded-full text-sm flex justify-center items-center cursor-pointer ${
                !isExpiredAssign
                  ? checkPageAlreadyRead(item?.id)
                    ? 'bg-blue_2 text-white'
                    : 'bg-EDEDED text-999999'
                  : activePage === index + 1
                  ? 'bg-blue_2 text-white'
                  : 'bg-EDEDED text-999999'
              }`}
              key={item?.id}
              onClick={() => {
                swiperRef.current?.slideTo(index);
              }}
            >
              {index + 1}
            </div>
          ))}

          {/* <ProgressBar
            now={(activePage / items.length) * 100}
            label={
              <>
                <div className="h-[8px] bg-blue_2 rounded"></div>
              </>
            }
            className="max-w-[324px] h-[8px]"
          />
          <p className="text-sm">{`${activePage}/${items.length}`}</p>
          <span className="hidden md:block">Pages</span> */}
        </div>
      </div>
      {bookType !== BookType.READ && (
        <div className="flex flex-col mx-auto gap-4 justify-center items-center my-4 border border-DEDEDE rounded-lg p-4 lg:fixed lg:left-[26px] lg:bottom-[33px] shadow-lg max-w-[365px] lg:w-[365px] lg:max-h-[72px] z-50">
          {loading && bookType === BookType.LISTEN ? (
            <div className="flex justify-center w-full">
              <Loading className="!my-0 !h-6 !w-6" />
            </div>
          ) : (
            (items[activePage - 1]?.audio1_url ||
              items[activePage - 1]?.audio2_url ||
              items[activePage - 1]?.audio3_url) && (
              <AudioPlayer
                isChangePage={isChangePage}
                curentSong={{
                  title: '',
                  src:
                    items[activePage - 1]?.audio1_url ||
                    items[activePage - 1]?.audio2_url ||
                    items[activePage - 1]?.audio3_url
                }}
                clasName="flex justify-center items-center"
                currentIndex={activePage - 1}
                selectedIndex={slideSelectedIndex}
              />
            )
          )}
        </div>
      )}
      {activePage === items.length &&
        bookType === BookType.READ &&
        accountType === AccoutType.PREMIUM && (
          <div className="flex justify-center gap-2 mb-3 lg:mb-0 lg:fixed lg:right-[26px] lg:bottom-[33px] z-50">
            <Button
              className="px-2 flex gap-2 justify-center items-center rounded-lg w-[196px]"
              url={`${
                isNextAssignmentStatus === NextAssignmentStatus.LISTENING
                  ? listBookPagesAlreadyRead?.covers?.length ===
                      items?.length || isExpiredAssign
                    ? `/books/view/${id}`
                    : ''
                  : idNextAssignment
                  ? `/books/view/${idNextAssignment}`
                  : '/list-assign'
              }`}
              style={`${
                isNextAssignmentStatus === NextAssignmentStatus.LISTENING
                  ? listBookPagesAlreadyRead?.covers?.length ===
                      items?.length || isExpiredAssign
                    ? 'secondary'
                    : 'disabled'
                  : 'secondary'
              }`}
            >
              {isNextAssignmentStatus === NextAssignmentStatus.LISTENING ? (
                <>
                  <IoCheckmark size={16} color="#235E5A" />
                  <p className="text-xs md:text-sm font-normal whitespace-nowrap">
                    Complete Reading
                  </p>
                </>
              ) : (
                <>
                  <TbPlayerTrackNext size={16} color="#235E5A" />
                  <p className="text-xs md:text-sm font-normal whitespace-nowrap">
                    Next Assignment
                  </p>
                </>
              )}
            </Button>
            <Button
              className="px-2 flex gap-2 justify-center items-center rounded-lg w-[196px]"
              url={`${
                isNextAssignmentStatus !== NextAssignmentStatus.LISTENING
                  ? listBookPagesAlreadyRead?.covers?.length === items?.length
                    ? '/list-assign?status=Completed'
                    : ''
                  : `/books/detail/listen-${id}`
              }`}
              style={`${
                isNextAssignmentStatus !== NextAssignmentStatus.LISTENING
                  ? listBookPagesAlreadyRead?.covers?.length === items?.length
                    ? 'primary'
                    : 'disabled'
                  : 'primary'
              }`}
            >
              {isNextAssignmentStatus === NextAssignmentStatus.LISTENING ? (
                <>
                  <FaHeadphones size={16} />
                  <p className="text-xs md:text-sm font-normal whitespace-nowrap">
                    Continue Listening
                  </p>
                </>
              ) : (
                <>
                  <IoCheckmark size={16} color="#F3FAFA" />
                  <p className="text-xs md:text-sm font-normal whitespace-nowrap">
                    Complete Assignment
                  </p>
                </>
              )}
            </Button>
          </div>
        )}
      {activePage === items.length &&
        bookType === BookType.LISTEN &&
        accountType === AccoutType.PREMIUM && (
          <div className="flex justify-center gap-2 mb-3 lg:mb-0 lg:fixed lg:right-[26px] lg:bottom-[33px] z-50">
            <Button
              className="px-2 flex gap-2 justify-center items-center rounded-lg w-[196px]"
              url={`${
                isNextAssignmentStatus === NextAssignmentStatus.READING
                  ? listBookPagesAlreadyRead?.pages?.length === items?.length ||
                    isExpiredAssign
                    ? `/books/view/${id}`
                    : ''
                  : idNextAssignment
                  ? `/books/view/${idNextAssignment}`
                  : '/list-assign'
              }`}
              style={`${
                isNextAssignmentStatus === NextAssignmentStatus.READING
                  ? listBookPagesAlreadyRead?.pages?.length === items?.length ||
                    isExpiredAssign
                    ? 'secondary'
                    : 'disabled'
                  : 'secondary'
              }`}
            >
              {isNextAssignmentStatus === NextAssignmentStatus.READING ? (
                <>
                  <IoCheckmark size={16} color="#235E5A" />
                  <p className="text-xs md:text-sm whitespace-nowrap font-normal">
                    Complete Listening
                  </p>
                </>
              ) : (
                <>
                  <TbPlayerTrackNext size={16} color="#235E5A" />
                  <p className="text-xs md:text-sm whitespace-nowrap font-normal">
                    Next Assignment
                  </p>
                </>
              )}
            </Button>
            <Button
              className="px-2 flex gap-2 justify-center items-center rounded-lg w-[196px]"
              url={`${
                isNextAssignmentStatus !== NextAssignmentStatus.READING
                  ? listBookPagesAlreadyRead?.pages?.length === items.length
                    ? '/list-assign?status=Completed'
                    : ''
                  : `/books/detail/read-${id}`
              }`}
              style={`${
                isNextAssignmentStatus !== NextAssignmentStatus.READING
                  ? listBookPagesAlreadyRead?.pages?.length === items?.length
                    ? 'primary'
                    : 'disabled'
                  : 'primary'
              }`}
            >
              {isNextAssignmentStatus === NextAssignmentStatus.READING ? (
                <>
                  <img src={book_icon} alt="Book Icon" className="w-4" />
                  <p className="text-xs md:text-sm whitespace-nowrap font-normal">
                    Continue Reading
                  </p>
                </>
              ) : (
                <>
                  <IoCheckmark size={16} color="#F3FAFA" />
                  <p className="text-xs md:text-sm whitespace-nowrap font-normal">
                    Complete Assignment
                  </p>
                </>
              )}
            </Button>
          </div>
        )}
      <div className="w-full relative">
        <div className="hidden md:flex gap-x-[10px] absolute md:justify-between w-full bottom-[50%] z-50">
          <Button
            style={`${activePage === 1 ? 'disabled' : 'secondary'}`}
            className="swiper-button-prev absolute top-[50%] left-[1%] lg:left-[14%] xl:left-[18%] md:w-[48px] md:h-[48px] !p-0"
          >
            <LiaAngleLeftSolid size={24} />
          </Button>
          <Button
            style={`${activePage === items.length ? 'disabled' : 'secondary'}`}
            className="swiper-button-next absolute top-[50%] right-[1%] lg:right-[14%] xl:right-[18%] md:w-[48px] md:h-[48px] !p-0"
          >
            <LiaAngleRightSolid size={24} />
          </Button>
        </div>
        <Swiper
          onSlideChange={handleSlideChange}
          loop={false}
          spaceBetween={30}
          autoplay={false}
          speed={500}
          pagination={{
            clickable: true,
            el: '.swiper-pagination',
            bulletClass: 'swiper-pagination-bullet',
            bulletActiveClass: 'swiper-pagination-bullet-active'
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
          slidesPerView={1}
          initialSlide={
            currentPageRead > 0 ? currentPageRead - 1 : currentPageRead
          }
          modules={[Autoplay, Pagination, Navigation]}
          onSwiper={(swiper: any) => (swiperRef.current = swiper)}
        >
          {items.map((item, index) => (
            <SwiperSlide key={item?.id + index}>
              <div className="relative w-full">
                <img
                  src={item?.url || item?.img_url}
                  alt="book"
                  className="w-full object-contain h-auto md:max-w-[541px] md:max-h-[607px] mx-auto p-2 border border-DEDEDE rounded-[32px]"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};
